import React from "react";
import moment from "moment";
import { overwriteOrdinalPlaceholder } from '@pit/image-generator';
import { FormContainer, Container, LogoContainer } from "./form.styles";
import { DateInput } from "../common/date-input";
import { Toggle } from "../common/toggle";
import { Input } from "../common/input";
import { StyledButton } from "../common/styled/styled-button";
import { Checkbox } from "../common/styled/checkbox";
import { Inline } from "../common/styled/inline";
import { LabelText } from "../common/styled/label-text";
import { StandardText } from "../common/styled/standard-text";
import { OptionSelector } from "../common/option-selector";
import { DATE_FORMATS } from "../../constants";
import { FormStateType, Properties } from "./state";
import { ScreenSizing } from "../../types";
import LOGO from "../../logo.png";

const Form = ({
  form,
  screenSizing,
  toggleReview,
  isMobile
}: {
  form: FormStateType;
  screenSizing: ScreenSizing;
  toggleReview: Function;
  isMobile: boolean;
}) => {
  const options = DATE_FORMATS[form.values[Properties.Locale]].map(format => ({
    label: overwriteOrdinalPlaceholder(
      `${format.label} - ${moment(
        form.values[Properties.SpecialDate] || new Date()
      ).format(format.value)}`
    ),
    value: format.value
  }));

  return (
    <FormContainer
      formWidth={
        screenSizing.isMobile ? screenSizing.innerWidth : screenSizing.formWidth
      }
    >
      <form onSubmit={form.handleSubmit}>
        <Container>
          <Inline>
            <LogoContainer src={LOGO} />
            <StandardText fontSize={32}>The Planets In Time</StandardText>
          </Inline>
        </Container>
        <Container>
          <DateInput
            name={Properties.SpecialDate}
            value={form.values[Properties.SpecialDate] || new Date()}
            onChange={date => form.setFieldValue(Properties.SpecialDate, date)}
            locale={form.values[Properties.Locale]}
          />
        </Container>
        <Container>
          <Toggle
            name={Properties.HidePluto}
            checked={form.values[Properties.HidePluto]}
            text="Hide pluto from the image?"
            onChange={e =>
              form.setFieldValue(Properties.HidePluto, e.target.checked)
            }
          />
          <Toggle
            name={Properties.OrbitLines}
            checked={form.values[Properties.OrbitLines]}
            text="Show orbit lines?"
            onChange={e =>
              form.setFieldValue(Properties.OrbitLines, e.target.checked)
            }
          />
        </Container>
        <Container>
          <LabelText>How would you like the date to be formatted?</LabelText>
          <OptionSelector
            name={Properties.DateStyle}
            value={form.values[Properties.DateStyle]}
            onChange={value => form.setFieldValue(Properties.DateStyle, value)}
            options={options}
          />
          <Toggle
            text="US Date Format"
            name={Properties.Locale}
            checked={form.values[Properties.Locale] === "us"}
            onChange={e => {
              const newLocale = e.target.checked ? "us" : "en";
              const newFormat =
                DATE_FORMATS[newLocale][
                  DATE_FORMATS[form.values[Properties.Locale]]
                    .map(({ value }) => value)
                    .indexOf(form.values[Properties.DateStyle])
                ];

              if (newFormat)
                form.setFieldValue(Properties.DateStyle, newFormat.value);
              form.setFieldValue(Properties.Locale, newLocale);
            }}
          />
        </Container>
        <Container>
          <Input
            name={Properties.LineOne}
            label="Personal message"
            value={form.values[Properties.LineOne]}
            placeholder="Top line"
            onChange={form.handleChange}
          />
          <Input
            name={Properties.LineTwo}
            value={form.values[Properties.LineTwo]}
            placeholder="Bottom line"
            onChange={form.handleChange}
          />
        </Container>
        <Container>
          {!isMobile && (
            <Input
              name={Properties.Email}
              type="email"
              value={form.values[Properties.Email]}
              label="Email"
              placeholder="Enter your email"
              onChange={form.handleChange}
            />
          )}
        </Container>
        <Container>
          {!isMobile && (
            <Inline>
              <Checkbox
                id="marketing-Checkbox"
                type="checkbox"
                name={Properties.Marketing}
                onChange={e =>
                  form.setFieldValue(Properties.Marketing, e.target.checked)
                }
                checked={form.values[Properties.Marketing]}
              />
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="marketing-Checkbox">
                <StandardText fontSize={12}>
                  I agree to receiving marketing material by email from The
                  Planets In Time
                </StandardText>
              </label>
            </Inline>
          )}
        </Container>
        <Container>
          {isMobile ? (
            <StyledButton onClick={() => toggleReview()}>REVIEW</StyledButton>
          ) : (
            <StyledButton type="submit">ORDER PRINT</StyledButton>
          )}
        </Container>
      </form>
    </FormContainer>
  );
};

export { Form };
