import styled from "styled-components";
import { Button } from "@chakra-ui/core";

export const StyledButton = styled(Button)`
  font-family: "News Cycle", sans-serif;
  font-size: 14px !important;
  font-weight: 700 !important;
  color: #050528;
  border: none;
  letter-spacing: 0.1em !important;
  text-transform: uppercase;
  background-color: #c7c7c7;
  width: 100%;
  padding: 15px 30px !important;
  height: auto !important;
`;
