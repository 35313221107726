export const CANVAS_PADDING = 10;
export const CANVAS_BACKGROUND_COLOR = "#0F1108";
export const FORM_WIDTH = 400;

export const DATE_FORMATS = {
  en: [
    { label: "Words", value: "Do MMMM YYYY" },
    { label: "Slashes", value: "DD/MM/YYYY" },
    { label: "Dots", value: "DD.MM.YYYY" },
    { label: "Dashes", value: "DD-MM-YYYY" }
  ],
  us: [
    { label: "Words", value: "MMMM Do YYYY" },
    { label: "Slashes", value: "MM/DD/YYYY" },
    { label: "Dots", value: "MM.DD.YYYY" },
    { label: "Dashes", value: "MM-DD-YYYY" }
  ]
};
