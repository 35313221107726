import styled from "styled-components";

interface Props {
  fontSize?: number;
  margin?: string;
}

const StandardText = styled.p`
  font-size: ${(props: Props) => props.fontSize || 16}px;
  line-height: ${(props: Props) => (props.fontSize || 16) + 1}px;
  margin: ${(props: Props) => props.margin || "3px 0"};
`;

export { StandardText };
