import React, { useState, useEffect } from "react";
import { ThemeProvider } from "@chakra-ui/core";
import "./global-styles.css";
import { FormikProvider } from "formik";
import { Planets } from "./components/planets/index";
import { Form } from "./components/form/index";
import { GlobalStyle, Container, PreloadFont } from "./App.styles";
import { theme } from "./theme";
import { FORM_WIDTH, CANVAS_PADDING } from "./constants";
import { useFormState, Properties } from "./components/form/state";
import { ScreenSizing } from "./types";

const isMobile = () => {
  return window.innerWidth < 800;
};

const getScreenSizing = () => {
  const formWidth =
    window.innerWidth < 800 ? window.innerWidth - 60 : FORM_WIDTH;
  const canvasSize = Math.min(
    isMobile()
      ? window.innerWidth - 40
      : window.innerHeight - 2 * CANVAS_PADDING,
    isMobile()
      ? window.innerWidth - 40
      : window.innerWidth - formWidth - 2 * CANVAS_PADDING
  );
  return {
    isMobile: isMobile(),
    innerWidth: window.innerWidth,
    formWidth,
    canvasSize
  };
};

const App: React.FC = () => {
  const [screenSizing, setScreenSizing] = useState<ScreenSizing>(
    getScreenSizing()
  );

  const [review, setReview] = useState(false);

  const toggleReview = () => setReview(!review);

  const form = useFormState();

  const resize = () => {
    setScreenSizing(getScreenSizing());
  };

  useEffect(() => {
    (document as any).fonts.ready.then(() => {
      if (!form.values[Properties.SpecialDate])
        form.setFieldValue(Properties.SpecialDate, new Date());
    });

    window.addEventListener("resize", resize);
    return () => window.removeEventListener("resize", resize);
  }, [form]);

  return (
    <>
      <GlobalStyle />
      <PreloadFont />
      <ThemeProvider theme={theme}>
        <FormikProvider value={form}>
          {isMobile() === true ? (
            <Container>
              {review === false ? (
                <Form
                  screenSizing={screenSizing}
                  form={form}
                  toggleReview={toggleReview}
                  isMobile={isMobile()}
                />
              ) : (
                <Planets
                  screenSizing={screenSizing}
                  toggleReview={toggleReview}
                  isMobile={isMobile()}
                />
              )}
            </Container>
          ) : (
            <Container>
              <Form
                screenSizing={screenSizing}
                form={form}
                toggleReview={toggleReview}
                isMobile={isMobile()}
              />
              <Planets
                screenSizing={screenSizing}
                toggleReview={toggleReview}
                isMobile={isMobile()}
              />
            </Container>
          )}
        </FormikProvider>
      </ThemeProvider>
    </>
  );
};

export { App };
