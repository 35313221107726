import { useFormik, useFormikContext } from "formik";
import { DATE_FORMATS } from "../../constants";

export enum Properties {
  SpecialDate = "Special Date",
  HidePluto = "Hide Pluto",
  OrbitLines = "Orbit Lines",
  DateStyle = "Date Style",
  LineOne = "Line One",
  LineTwo = "Line Two",
  Email = "Email",
  Marketing = "Marketing",
  Locale = "Locale"
}

const DEFAULT_LOCALE = "us";

export const useFormState = () =>
  useFormik({
    onSubmit: values => {
      // Properties passed to shopify and into the webhook
      const properties = {
        [Properties.HidePluto]: values[Properties.HidePluto],
        [Properties.SpecialDate]: values[Properties.SpecialDate],
        [Properties.DateStyle]: values[Properties.DateStyle],
        [Properties.LineOne]: values[Properties.LineOne],
        [Properties.LineTwo]: values[Properties.LineTwo],
        [Properties.OrbitLines]: values[Properties.OrbitLines]
      };

      const params = Object.keys(properties).reduce(
        (cur, next) =>
          `${cur}&properties[${next}]=${(properties as any)[next]}`,
        ""
      );

      window.location.href = `https://planetsintime.com/cart/add?id=29244802793536&quantity=1&${params}`;
    },
    initialValues: {
      [Properties.SpecialDate]: new Date(),
      [Properties.HidePluto]: false,
      [Properties.OrbitLines]: true,
      [Properties.DateStyle]: DATE_FORMATS[DEFAULT_LOCALE][0].value,
      [Properties.LineOne]: "",
      [Properties.LineTwo]: "",
      [Properties.Email]: "",
      [Properties.Marketing]: false,
      [Properties.Locale]: DEFAULT_LOCALE as keyof typeof DATE_FORMATS
    }
  });

export type FormStateType = ReturnType<typeof useFormState>;

export const useFormStateContext = () =>
  useFormikContext<FormStateType["values"]>();
