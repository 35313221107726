import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: 'News Cycle', sans-serif;
  }

  button {
    cursor: pointer;
  }

  * {
    box-sizing: border-box;
  }
`;

export const Container = styled.div`
  display: flex;
  height: 100%;
`;

export const PreloadFont = styled.div`
  font-family: "Selectric Orator";
`;
