import styled from "styled-components";

export const InputContainer = styled.div`
  margin: 10px 0;
`;

interface InputProps {
  valid: boolean;
  value: string;
  validated: boolean;
}

export const StyledInput = styled.input`
  width: calc(100% - 35px);
  border: 2px solid
    ${(props: InputProps) => {
      if (props.valid) {
        return "#2CEAA390";
      }
      return props.value && props.validated ? "#E85D7590" : "lightgray";
    }};
  padding: 7px 15px;
  border-radius: 3px;
  font-size: 14px;
  outline: none;
  margin-bottom: 5px;

  &:focus {
    border-color: ${(props: InputProps) =>
      props.valid || !props.validated ? "#2CEAA3" : "#E85D75"};
  }
`;
