import styled from "styled-components";

export const Select = styled.select`
  width: 100%;
  border: 2px solid lightgray;
  padding: 0 5px;
  height: 35px;
  background: transparent;
  border-radius: 2px;
  font-size: 13px;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  border: 2px solid #d1d1d190;
  text-indent: 5px;
  outline: none;
  font-family: "News Cycle", sans-serif;
  font-size: 16px !important;
`;

export const Option = styled.option``;
