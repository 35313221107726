import styled from "styled-components";
import { CANVAS_PADDING } from "../../constants";

interface CanvasContainerProps {
  display: boolean;
  isMobile: boolean;
}

export const CanvasContainer = styled.section<CanvasContainerProps>`
  display: ${props => (props.display ? "flex" : "none")};
  justify-content: center;
  padding: ${CANVAS_PADDING}px;
  box-sizing: border-box;
  align-items: center;
  background: ${props => (props.isMobile ? "white" : "#f3f3f3")};
  width: 100%;
  height: ${props => (props.isMobile ? "auto" : "100%")};
`;

export const Canvas = styled.canvas``;

export const PlanetsContainer = styled.div`
  display: flex;
  background: white;
  flex-direction: column;
  flex: 1;
  min-height: 100vh;
`;

export const MobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  background: white;
`;

export const Container = styled.div`
  padding: 10px;
`;
