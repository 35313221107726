import React from "react";
import { Input as ChakraInput } from "@chakra-ui/core";
import styled from "styled-components";
import { InputContainer } from "./input.styles";
import { LabelText } from "../styled/label-text";
import { StandardText } from "../styled/standard-text";

interface Props {
  value: string;
  name: string;
  label?: string;
  type?: string;
  placeholder: string;
  explainerText?: string;
  onChange: (event: React.FormEvent<HTMLElement>) => void;
  validate?: (str: string) => boolean;
}

const StyledChakraInput = styled(ChakraInput)`
  font-family: "News Cycle", sans-serif;
  font-size: 16px !important;
`;

const Input = ({
  value,
  placeholder,
  onChange,
  label,
  validate,
  name,
  type,
  explainerText
}: Props) => {
  const colorWhenInvalid = !!validate && value ? "#E85D75" : "#d1d1d1";
  const borderColor =
    !!validate && validate(value) && value ? "#2CEAA3" : colorWhenInvalid;

  return (
    <InputContainer>
      {label && <LabelText>{label}</LabelText>}
      <StyledChakraInput
        fontSize={14}
        isInvalid={!!validate && !validate(value)}
        placeholder={placeholder}
        type={type || "text"}
        name={name}
        value={value}
        borderColor={`${borderColor}90`}
        borderWidth={2}
        focusBorderColor={borderColor}
        onChange={onChange}
      />
      {explainerText && (
        <StandardText fontSize={11}>{explainerText}</StandardText>
      )}
    </InputContainer>
  );
};

export { Input };
