import styled from "styled-components";

interface ContainerProps {
  formWidth: number;
}

export const FormContainer = styled.section`
  padding: 10px 30px 10px 30px;
  width: ${(props: ContainerProps) => props.formWidth}px;
  max-width: ${(props: ContainerProps) => props.formWidth}px;
  min-width: ${(props: ContainerProps) => props.formWidth}px;
  background: white;
  height: 100%;
  overflow: auto;
`;

export const Container = styled.div`
  margin: 0 0 25px 0;
`;
export const LogoContainer = styled.div<{ src: string }>`
  height: 70px;
  width: 70px;
  border-radius: 35px;
  background: #f1f1f1;
  border-radius: 1px solid #e3e3e3;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-size: cover;
  background-image: url("${props => props.src}");
`;
