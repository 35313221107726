import React from "react";
import { Select, Option } from "./option-selector.styles";

interface OptionType {
  value: string;
  label: string;
}

interface Props {
  options: OptionType[];
  value: string;
  onChange: (e: any) => null;
  name: string;
}

const OptionSelector = ({ options, onChange, value, name }: Props) => (
  <Select
    name={name}
    value={value}
    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
      onChange(e.target.value);
    }}
  >
    {options.map(option => (
      <Option key={option.value} value={option.value}>
        {option.label}
      </Option>
    ))}
  </Select>
);

export { OptionSelector };
