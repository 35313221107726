import styled from "styled-components";
import { StandardText } from "./standard-text";

interface Props {
  fontSize?: number;
}

const LabelText = styled(StandardText)`
  font-weight: normal;
  font-size: ${({ fontSize }: Props) => fontSize || 16}px;
  margin-bottom: 8px;
`;

export { LabelText };
