import React from "react";
import DatePicker from "react-date-picker";
import { InputContainer } from "./date-input.styles";
import { LabelText } from "../styled/label-text";

interface Props {
  value: Date;
  onChange: (date: Date) => void;
  name: string;
  locale: string;
}

const DateInput = ({ value, onChange, name, locale }: Props) => {
  return (
    <InputContainer>
      <LabelText>Enter your special date</LabelText>
      <DatePicker
        clearIcon={null}
        name={name}
        onChange={newDate => {
          if (newDate) onChange(Array.isArray(newDate) ? newDate[0] : newDate);
        }}
        value={value}
        locale={locale === "en" ? "en-GB" : "en-US"}
      />
    </InputContainer>
  );
};

export { DateInput };
