import React, { useRef, useEffect, useState } from "react";
import { writeImageToCanvas } from "@pit/image-generator";
import {
  PlanetsContainer,
  CanvasContainer,
  Canvas,
  Container,
  MobileContainer
} from "./planets.styles";
import { useFormStateContext, Properties } from "../form/state";
import { Checkbox } from "../common/styled/checkbox";
import { Inline } from "../common/styled/inline";
import { Input } from "../common/input/input.ui";
import { StyledButton } from "../common/styled/styled-button";
import { StandardText } from "../common/styled/standard-text";
import { ScreenSizing } from "../../types";
import { Loader } from "../common/loader/loader.ui";
/**
 * TODO: This is not an ideal solution.
 * However this solves a problem where resizing the screen quickly will render
 * mutiple canvases and with in correct sizing.
 */
const useDebounce = (value: any, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]); // Only re-call effect if value or delay changes

  return debouncedValue;
};

const Planets = ({
  screenSizing,
  toggleReview,
  isMobile
}: {
  screenSizing: ScreenSizing;
  toggleReview: Function;
  isMobile: boolean;
}) => {
  const [toggleLoader, setToggleLoader] = useState(true);
  const form = useFormStateContext();
  const myCanvasRef = useRef<HTMLCanvasElement>(null);
  const delayedScreenSize = useDebounce(screenSizing, 500);

  useEffect(() => {
    const date = form.values[Properties.SpecialDate];
    const setCanvas = async () => {
      if (myCanvasRef.current && date) {
        await writeImageToCanvas(
          date.toString(),
          myCanvasRef.current as any,
          !form.values[Properties.HidePluto],
          [form.values[Properties.LineOne], form.values[Properties.LineTwo]],
          form.values[Properties.DateStyle],
          form.values[Properties.OrbitLines],
          "Selectric Orator",
          true
        );
        setToggleLoader(false);
      } else {
        setToggleLoader(true);
      }
    };
    setCanvas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.values, delayedScreenSize, myCanvasRef.current]);

  return (
    <PlanetsContainer>
      {toggleLoader && <Loader />}
      <CanvasContainer isMobile={isMobile} display={!toggleLoader}>
        <Canvas
          onContextMenu={e => e.preventDefault()}
          ref={myCanvasRef}
          style={{
            height: screenSizing.canvasSize,
            width: screenSizing.canvasSize
          }}
        />
        {/* Leave here for test purposes. */}
        {/* <img
          style={{
            opacity: 0.3,
            position: "absolute",
            height: screenSizing.canvasSize,
            width: screenSizing.canvasSize
          }}
          src={require("../../manually-generated-image-for-comparison.jpg")}
        /> */}
      </CanvasContainer>

      {isMobile && (
        <MobileContainer>
          <form onSubmit={form.handleSubmit}>
            <Container>
              <Input
                name={Properties.Email}
                type="email"
                value={form.values[Properties.Email]}
                label="Email"
                placeholder="Enter your email"
                onChange={form.handleChange}
              />
            </Container>
            <Container>
              <Inline>
                <Checkbox
                  id="marketing-Checkbox"
                  type="checkbox"
                  name={Properties.Marketing}
                  onChange={e =>
                    form.setFieldValue(Properties.Marketing, e.target.checked)
                  }
                  checked={form.values[Properties.Marketing]}
                />
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor="marketing-Checkbox">
                  <StandardText fontSize={12}>
                    I agree to receiving marketing material by email from The
                    Planets In Time
                  </StandardText>
                </label>
              </Inline>
            </Container>
            <Container>
              <StyledButton onClick={() => toggleReview()}>EDIT</StyledButton>
            </Container>
            <Container>
              <StyledButton type="submit">ORDER PRINT</StyledButton>
            </Container>
          </form>
        </MobileContainer>
      )}
    </PlanetsContainer>
  );
};

export { Planets };
