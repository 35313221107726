import styled from "styled-components";

export const InputContainer = styled.div`
  margin: 10px 0;

  .react-date-picker {
    width: 100%;

    .react-date-picker__wrapper {
      padding: 3px 10px;
      width: 100%;
      border: 2px solid #d1d1d190;
      border-radius: 0.25rem;
      font-size: 16px;
    }
  }
`;

interface InputProps {
  valid: boolean;
  value: string;
}
