import { theme as chakraTheme } from "@chakra-ui/core";

const theme = {
  ...chakraTheme,
  colors: {
    purple: "#6558f5",
    green: "#2CEAA3"
  },
  icons: {}
};

export { theme };
